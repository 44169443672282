import { onAuthStateChanged } from "firebase/auth";
import React, { createContext, useEffect, useState } from "react";
import { auth } from "./firebaseConfig";

export const AuthContext = createContext()

export function AuthContextProvider({ children }) {

    const [currentUser, setCurrentUser] = useState(0)
    // const navigate = useNavigate()
    useEffect(() =>
        onAuthStateChanged(auth, (user) => {
            setCurrentUser(user)
        }), [])

    return (
        <AuthContext.Provider value={currentUser}>
            {children}
        </AuthContext.Provider>
    )
}