// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Chat from './components/Chat/Chat'
import Analytics from './components/Analytics/Analytics';
import { AuthContextProvider } from './AuthContextProvider';
import Subscription from './components/Subscription/Subscription'

function App() {
    return (
        <AuthContextProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<Chat />} />
                    <Route path="/analytics" element={<Analytics />} />
                    <Route path="/subscription" element={
                        <Subscription />
                    } />

                </Routes>
            </Router>
        </AuthContextProvider>
    );
}

export default App;
