import React from 'react'

import data from '../../data.json'
const { pics, names, bios } = data

export default function MiddleTop(
    { setShown, setClosed, influencerID, isCollapsed, setIsCollapsed }
) {
    return (
        <div className="top">
            <div className='mini'>
                <button className="back icon back2"
                    onClick={() => setShown(0)}>
                    {/* <img src='back.svg' className='dots' height='16' /> */}
                    <svg stroke="#a0a0a0" fill="#a0a0a0" strokeWidth="0" viewBox="0 0 448 512" height="26px" width="26px" xmlns="http://www.w3.org/2000/svg">
                        <path d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z"></path>
                    </svg>
                </button>

                <div className="profile">
                    {
                        isCollapsed && <div className="icon collapse-icon"
                            onClick={() => setIsCollapsed(!isCollapsed)}
                            style={{
                                // position: "absolute",
                                top: '.5em',
                                right: ".5em",
                                transform: `rotate(${isCollapsed ? 180 : 0}deg)`,
                                transition: 'transform 0.3s ease'
                            }}>
                            <svg stroke="#a0a0a0" fill="#a0a0a0" strokeWidth="0" viewBox="0 0 448 512" height="26px" width="26px" xmlns="http://www.w3.org/2000/svg">
                                <path d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z"></path>
                            </svg>
                        </div>
                    }
                    <img src={pics[influencerID]} alt="" />
                    <div className="desc">
                        <div className="name">{names[influencerID]}</div>
                        <div className="bio">{bios[influencerID]} </div>
                    </div>
                </div>
            </div>
            <button style={{ border: "none" }} className="icon" onClick={() => {
                setClosed(e => !e)
                setShown(2)
            }}>
                <img src='dots.svg' className='dots' height='22' />
            </button>
        </div>
    )
}
