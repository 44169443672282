// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBV0HMjlOHcA0Xq3thjq8pEuexOIuvOh6o",
    authDomain: "alphatwin-ed95e.firebaseapp.com",
    projectId: "alphatwin-ed95e",
    storageBucket: "alphatwin-ed95e.appspot.com",
    messagingSenderId: "939781365336",
    appId: "1:939781365336:web:f2876235e1c1e78e96742d",
    measurementId: "G-2GLCKHC4D3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase
export const firestore = getFirestore(app);

// Initialize Firebase Authentication
const auth = getAuth(app);

setPersistence(auth, browserLocalPersistence)

export { auth }