const EMAIL_KEY = 'email';

export const getEmailId = () => {
    let emailId = localStorage.getItem(EMAIL_KEY);
    if (!emailId) {
        emailId = 'no-email';
        localStorage.setItem(EMAIL_KEY, emailId);
    }
    return emailId;
};

export const setEmailId = (newEmail) => {
    localStorage.setItem(EMAIL_KEY, newEmail);
};
