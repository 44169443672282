import React, { useEffect, useState } from 'react'
import Links from './Links';
import data from '../../data.json'

const { names, pics2, bios } = data

export default function RightBar({
    closed, influencerID, setClosed, shown, setShown,
    testMode, setTestMode
}) {
    const [readMore, setReadMore] = useState(false)
    useEffect(() => setReadMore(false), [influencerID])

    return (
        <div className={`rightBar ${closed && "closed"} ${shown === 2 ? "shown" : "notShown"}`}>
            <div className="card" style={{ height: "100%", position: "relative" }}>
                <div className="text meet">
                    <button className="back icon"
                        onClick={() => {
                            setClosed(c => !c)
                            setShown(1)
                        }}>
                        <img src='back.svg' className='dots' height='16' />
                    </button>
                    Meet {names[influencerID]}
                    {
                        (window.location.href.startsWith("http://localhost") || window.location.href.includes("192.168.")) &&
                        <button style={{ backgroundColor: testMode ? "green" : "red" }} onClick={() => setTestMode(t => !t)}>
                            Test Mode
                        </button>
                    }
                </div>

                <div className="picContainer">
                    <img src={pics2[influencerID]} alt="" height="408" width="320" />
                    <div className="o"></div>
                    <div className="nameTitle">{names[influencerID]}</div>
                    <div className="nameDesc">{bios[influencerID]}</div>
                </div>

                <div className='desc desc2' style={{ marginTop: "2em", display: "block" }}>
                    {influencerID === 'alex' ?
                        <>
                            Alex Hormozi is an entrepreneur and investor who has built multiple businesses to over $100
                            million in revenue and is known for his expertise in sales and scaling companies. He is the author of
                            three bestselling books, including $100M Offers: How
                        </> : <>
                            AI Guy is a YouTube entrepreneur who reached 170,000 subscribers and $27,000 per month in revenue in under
                            one year by leveraging the power of artificial intelligence. Known for his hands-on approach, he teaches exactly
                            what he did to achieve
                        </>}
                    {"  "}

                    {!readMore ? <>
                        {" "} <a className='link' onClick={() => setReadMore(r => !r)}>Read More...</a>
                    </> : <>
                        {
                            influencerID === 'alex' ?
                                <>Offers So Good People Feel Stupid Saying No. Alex and his wife Leila are managing partners of Acquisition.com,
                                    a portfolio of companies generating $200 million annually. Notably, they they successfully packaged and licensed a
                                    boutique gym model to over 5,000 gyms worldwide, and later sold a significant stake in their supplement and software
                                    company for $46.2 million. They focus on making minority investments in cash flow-positive, founder-owned businesses,
                                    and are dedicated to providing real business education through content, books, and free courses. Alex candidly shares his
                                    entrepreneurial lessons and continues to document his journey from a $100 million net worth to a billion. </>
                                :
                                <>these results and continues to apply these strategies in his own business. Unlike many online gurus, AI Guy actively
                                    practices what he preaches, and his mission is to help newcomers earn their first dollar online as quickly as possible.</>
                        }
                        {" "}<a className='link' onClick={() => setReadMore(r => !r)}>Show less...</a>
                    </>}
                </div>

                <div className="bottom">
                    <div className='text' style={{ fontSize: "16px" }}>Find {names[influencerID]}</div>
                    <Links influencerID={influencerID} />
                </div>
            </div>
        </div>
    )
}
