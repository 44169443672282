import React from 'react'
import { motion } from 'framer-motion'

const duration = 1.0 //Animation Length

const yMax = 10 //How high it reaches

const yMin = 10 //How low it goes before going back to default y

const styles = {
    bubble: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80px',
        minHeight: '35px',
        backgroundColor: 'rgba(255, 255, 255, .07)',
        paddingBlock: "0px"
        // borderRadius: '20px',
    },
    dot: {
        width: '8px',
        height: '8px',
        backgroundColor: '#eeeeee',
        borderRadius: '50%',
        margin: '0 2px',
    },
};

const anim = {
    opacity: [0.3, 1, 0.3],
    y: [0, `${yMax}%`, `-${yMin}%`, 0]
}

const trans = {
    duration,  // Slower animation
    repeat: Infinity,
    repeatType: 'loop',
    ease: 'easeInOut',
}

export default function TypingIndicator() {
    return (
        <motion.div
            positionTransition
            className={`message receiving`}
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, transition: { duration: 0.15 } }}
            style={styles.bubble}
        >
            <motion.span style={styles.dot}
                initial={{ opacity: 0.3 }}
                animate={{
                    ...anim,
                    transition: {
                        ...trans
                    },
                }}
            />
            <motion.span
                style={styles.dot}
                initial={{ opacity: 0.3 }}
                animate={{
                    ...anim,
                    transition: {
                        ...trans,
                        delay: .25
                    },
                }}
            />
            <motion.span
                style={styles.dot}
                initial={{ opacity: 0.3 }}
                animate={{
                    ...anim,
                    transition: {
                        ...trans,
                        delay: .5
                    }
                }}
            />
        </motion.div>
    )
}
