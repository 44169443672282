import React from 'react'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../firebaseConfig';

const provider = new GoogleAuthProvider();

export default function Modal({ isOpen, onClose }) {
    const [isClosing, setIsClosing] = React.useState(false);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(onClose, 200);
    };

    const signInWithGoogle = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                const user = result.user;
                handleClose();
            })
            .catch((error) => {
                console.error('Error during sign-in:', error);
            });
    };

    React.useEffect(() => {
        if (isOpen) {
            setIsClosing(false);
            document.body.style.overflow = 'hidden';
        }

        const handleEscape = (e) => {
            if (e.key === 'Escape') handleClose();
        };

        document.addEventListener('keydown', handleEscape);
        return () => {
            document.removeEventListener('keydown', handleEscape);
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className={`modal-overlay ${isClosing ? 'fadeOut' : ''}`} onClick={handleClose}>
            <div className={`modal-content ${isClosing ? 'slideDown' : ''}`} onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={handleClose}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
                <div className="modal-body">
                    <svg className="modal-icon" viewBox="0 0 24 24" width="64" height="64">
                        <path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z" />
                    </svg>
                    <h2>Sign In Required</h2>
                    <p className="modal-description" style={{fontFamily: "Open Sans", fontSize: "15px"}}>
                        To start chatting and unlock all features, please sign in with your Google account. It only takes a few seconds!
                    </p>
                    <button className="modal-button" onClick={signInWithGoogle}>
                        <svg className="google-icon" viewBox="0 0 24 24" width="24" height="24">
                            <path fill="currentColor" d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z" />
                        </svg>
                        Continue with Google
                    </button>
                </div>
            </div>
        </div>
    );
};